
import { createI18n } from 'vue-i18n'
import enLocale from './en'
import zhLocale from './zh'
const messages = {
  en: {
    ...enLocale
  },
  zh: {
    ...zhLocale
  }
}
export default createI18n({
  legacy: false,
  allowComposition: true,
  locale: localStorage.getItem("lang") || "zh",
  messages
})