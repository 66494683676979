import axios from "axios"
import store from "../store/index.js";
import { ElLoading, ElMessage } from 'element-plus'


// import { ElMessage } from 'element-plus'


let LoadingObj = null;

//使用create创建axios
const Service = axios.create({
  timeout: 8000,
  baseURL: '/api', //api会自动加载
  headers: {
    "Content-Type": "application/json;chartset=utf-8",
    "Authorization": store.state.common.token ? store.state.common.token : ''  //store.state.userInfo.userInfo ? store.state.userInfo.userInfo.token : ''
  }
})

//请求拦截(拦截器) 在请求之前做些什么（获取并设置token）
Service.interceptors.request.use(config => {
  config.headers['Authorization'] = store.state.common.token
  LoadingObj = ElLoading.service({
    lock: true,
    text: "加载中",
    background: 'rgb(56, 86, 139)'
  })
  return config;
}, (error) => {
  return Promise.reject(error);
})

//响应拦截 对返回值做统一处理（响应拦截器）对响应数据做些什么
Service.interceptors.response.use(response => {
  LoadingObj.close();
  const res = response.data
  if (res.code == 400 || !res.data) {
    ElMessage.error(res.msg || "服务器出错了。")
    return res.data;
  }
  return res.data;
}, (error) => {
  LoadingObj.close();
  ElMessage({
    message: "服务器错误",
    type: "error",
    duration: 2000
  })
  return Promise.reject(error);
})

//post请求
export const post = config => {
  return Service({
    ...config,
    method: "post",
    params: config.data,
  })
}

//get请求
export const get = config => {
  return Service({
    ...config,
    method: "get",
    params: config.data
  })
}
//put请求 更新
export const put = config => {
  return Service({
    ...config,
    method: "put",
    params: config.data,
  })
}
//delete请求 更新
export const reqDelete = config => {
  return Service({
    ...config,
    method: "delete"
  })
}
