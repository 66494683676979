<template>

  <!--相当于a标签 to="path"-->
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <!--展示内容-->
  <router-view />

</template>

<script>
  import { useI18n } from 'vue-i18n'
  // import {provide} from 'vue'
  // import * as echarts from 'echarts'

  export default ({
    name: 'App',
    setup() {

      const { t, locale } = useI18n({
        inheritLocale: true,
        useScope: 'global'
      })
      return {
        t, locale
      }
    }

  })
</script>
<style>
#app{
  height: 100%;
  color: #2c3e50;
/* font-family: Avenir,  Helvetica, Arial, sans-serif;
-webkit-font-smoothing:antialiased;
-moz-osx-font-smoothing: grayscale;*/

}
.page_content {
    /* box-sizing: border-box; */
    display: block;
    width: 99%;
    height: 98%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background-color: rgb(255, 254, 254);
    /* border: 1px solid firebrick; */
  }
  .divider_title{
   margin: 15px !important;
   width:99%;
   margin-bottom:5px;
  }
  .input_search {
    margin-bottom: 0.1%;
    margin-left: 4%;
    border:0px solid red;
    display: inline-block;
  }
  .input_search_left {
    width:65%;
  }

 table tbody tr td{
   padding: 3px 0 !important;
}
.el-table{
 margin-left: 10px;
}
.text_hidden_one{
  white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
}
.text_hidden_more{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.add_title{
    text-align: center;
    padding-top: .5rem;
    font-size: 25px;
    font-weight: 1000;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
.add{
    border:0px solid red;
    height: 97%;
    padding-top: 2%;
    }

</style>