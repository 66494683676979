export default {
  // you need to specify that!
  title: "融德项目管理系统",
  logout: "退出",
  welcome: "欢迎",
  welcome_text: "你好，请开启你美好的一天！",
  welcome_hi: "嗨",
  menu: {
    main: "首页",
    priojectM: "工程项目管理",
    prioject: "工程项目",
    projectStatistics: "项目统计",
    devProjectM: "研发项目管理",
    devProject: "研发项目",
    xsrkM: "型式认可管理",
    xsrk: "型式认可",
    personal: "个人中心",
    workRecord: "周总结",
    system: "系统设置",
    sUserM: "用户管理",
    sRoleM: "角色管理",
    sDepartmentM: "部门管理",
    lookkb: "智慧看板"
  },
  comm: {

  }

}