import { createStore } from 'vuex'

const modules = {};
//动态引入state下面得所有子文件中的.js文件  webpack提供。
//1,moduleFiles(路径)拿到模块对象 或者.导出名
//moduleFiles.keys() 可以获得所有相关路径
//从路径中获取order/user模块名
const moduleFiles = require.context('./state', true, /\.js$/)
moduleFiles.keys().forEach(filepath => {
  const moduleObj = moduleFiles(filepath).default;
  // 获取到不同的业务模块名称
  const reg = /^\.\/(.*)\.js$/;
  const res = reg.exec(filepath);
  if (res) {
    const moduleName = res[1];
    modules[moduleName] = {
      namespaced: true,//独有的命名空间
      ...moduleObj
    };

  }
});


export default createStore({
  state: { // 数据
  },
  mutations: { // 变更
  },
  actions: { // 异步业务
  },
  getters: {}, // 获取方式
  modules
})
