import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
//哈希路由带#和正常路由
const routes = [
  {
    path: '/',
    component: () => import('../views/Layout.vue'),
    name: 'home',
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/Home2.vue')
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/User/UsersList.vue')
      },
      {
        name: 'project',
        path: '/project',
        children: [
          {
            path: '/project',
            name: 'projectList',
            component: () => import('../views/Project/ProjectList.vue'),
          },
          {
            path: '/addProject',
            name: 'addProject',
            component: () => import('../views/Project/AddProject.vue')
          },
          {
            path: '/projectDetail',
            name: 'projectDetail',
            component: () => import('../views/Project/ProjectDetail.vue')
          }
        ]
      },
      {
        path: '/devProject',
        name: 'devProject',
        component: () => import('../views/DevProject/DevProjectList.vue')
      },
      {
        path: '/workRecord',
        name: 'workRecord',
        component: () => import('../views/WorkRecord/WorkRecord.vue')
      },
      {
        path: '/system',
        name: 'system',
        children: [
          {
            path: '/role',
            name: 'role',
            component: () => import('../views/Role/RolesList.vue')
          },
          {
            path: '/department',
            name: 'department',
            component: () => import('../views/Department/DepartmentList.vue')
          }]
      }


    ]
  },
  {
    path: '/smark',
    name: 'smark',
    component: () => import('../views/SmartKanBan/SmartKanBan.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/NotFount.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
//路由守卫
// router.beforeEach((to, form, next) => {
//   /**
//    * to 从哪个页面
//    * form 到哪个页面
//    * next 只有执行next（） 页面才会进行跳转
//    */
//   //判断用户是否登录 //
//   if (!store.state.userInfo.userInfo) {
//     //未登录
//     if (to.path === "/login") {
//       next()
//       return
//     }
//     next("/login")
//   }
//   else {
//     next()
//   }

// })

export default router
