export default {
  //独有的命名空间
  state: {
    userInfo: (localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem("userInfo"))) || null,
    isLogin: false,
    userMenus: {},
    isCollapse: true,
    currentMenu: null,
    tabsList: [
      {
        path: '/',
        name: 'home',
        title: '首页',
        icon: 'home'
      }
    ],
    menu: [],
    token: '',
    userLists: [],
    priorityLists: []
  },
  mutations: {
    setUserInfo(state, uInfo) {
      state.userInfo = uInfo
    },
    setPriorityLists(state, val) {
      state.priorityLists = val
      localStorage.setItem('priorityLists', JSON.stringify(val))
    },
    setUserList(state, val) {
      state.userLists = val
      localStorage.setItem('userLists', JSON.stringify(val))
    },
    setUserInfo(state, val) {
      state.userInfo = val
      localStorage.setItem('userInfo', JSON.stringify(val))
    },
    updateIsCollapse(state, payload) {
      state.isCollapse = !state.isCollapse
    },
    selectMenu(state, val) {
      // 判断
      // val.name == 'home' ? (state.currentMenu = null) : (state.currentMenu = val)
      if (val.name == 'home') {
        state.currentMenu = null
      } else {
        state.currentMenu = val
        let result = state.tabsList.findIndex(item => item.name === val.name)
        result == -1 ? state.tabsList.push(val) : ''
      }
    },
    closeTab(state, val) {
      let res = state.tabsList.findIndex(item => item.name === val.name)
      state.tabsList.splice(res, 1)
    },
    setMenu(state, val) {
      state.menu = val
      localStorage.setItem('menu', JSON.stringify(val))
    },
    addMenu(state, router) {
      if (!localStorage.getItem('menu')) {
        return
      }
      const menu = JSON.parse(localStorage.getItem('menu'))
      state.menu = menu

      const menuArray = []

      menu.forEach(item => {
        if (item.children) {
          item.children = item.children.map(item => {
            let url = `../views/${item.url}.vue`

            item.component = () => import(url)
            return item
          })
          menuArray.push(...item.children)
        } else {
          let url = `../views/${item.url}.vue`
          item.component = () => import(url)
          menuArray.push(item)
        }
      })

      menuArray.forEach(item => {
        router.addRoute('home', item)
      })
    },
    cleanMenu(state) {
      state.menu = []
      localStorage.removeItem('menu')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('userLists')
      localStorage.removeItem('priorityLists')
      state.token = ''
      Cookie.remove('token')
      localStorage.clear()
    },
    setToken(state, val) {
      state.token = val
      Cookie.set('token', val)
    },
    clearToken(state) {
      state.token = ''
      Cookie.remove('token')
    },
    getToken(state) {
      state.token = state.token || Cookie.get('token')
    }
  },
}