export default {
  // you need to specify that!
  title: "RD Project Management System",
  logout: "Logout",
  welcome: "Welcome",
  welcome_text: "Hello, please start your beautiful day!",
  welcome_hi:"Hi",
  menu: {
    main: 'Main',
    priojectM: "Project M",
    prioject: "Project",
    projectStatistics: "Project Statistics",
    devProjectM: "DevProject M",
    devProject: "DevProject",
    xsrkM: "型式认可管理",
    xsrk: "型式认可",
    personal: "Personal",
    workRecord: "Work Record",
    system: "System",
    sUserM: "User M",
    sRoleM: "Role M",
    sDepartmentM: "Department M",
    lookkb: "Smart Kanban"
  },
  comm: {

  }

}