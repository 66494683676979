import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import * as echarts from 'echarts'
// import 'echarts-gl' // 3d图表库
import Axios from "axios"
import './assets/sass/index.scss'
import 'element-plus/es/components/message/style/css'
import 'lib-flexible/flexible.js'
import i18n from './i18n/index.js'
import dataView from '@jiaminghi/data-view'
import service from '@/api/service.js';

//配置Axios,element-plus(Vue3)
const app = createApp(App)

//icon 的注册
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// app.config.globalProperties.$api=api
store.commit("common/addMenu", router);
function checkRouter(path) {
  let hasCheck = router.getRoutes().filter(route => route.path == path).length
  if (hasCheck) {
    return true
  } else {
    return false
  }
}

router.beforeEach((to, from, next) => {
  store.commit('common/getToken')
  const token = store.state.common.token
  if (!token && to.name !== 'login') {
    next({ name: 'login' })
  } else if (!checkRouter(to.path)) {
    next({ name: 'home' })
  } else {
    next()
  }
})
app.use(store).use(router).use(ElementPlus).use(service).use(i18n).use(dataView).mount('#app')